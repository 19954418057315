export class VideoFrame {
    rootElementId: string;
    src: string;
    frame: HTMLIFrameElement;

    constructor(rootElementId: string, src: string) {
        this.rootElementId = rootElementId;
        this.src = src;
        const rootElement = document.getElementById(rootElementId) as HTMLElement;
        if(rootElement === null) {
            throw new Error('No root element found');
        }
        this.frame = document.createElement('iframe');
        if(this.frame === null) {
            throw new Error('No video player found');
        }
        this.frame.setAttribute('src', src);
        this.frame.setAttribute('src', this.src);
        this.frame.setAttribute('title', 'YouTube video player');
        this.frame.setAttribute('frameborder', '0');
        this.frame.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
        this.frame.setAttribute('allowfullscreen', '');
        this.frame.setAttribute('referrerPolicy', "strict-origin-when-cross-origin");
        rootElement.appendChild(this.frame);
    }

    getFrame(): HTMLIFrameElement {
        return this.frame;
    }
}